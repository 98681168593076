import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import GoogleMapReact from "google-map-react";
import HeadquartersCard from "src/components/locations/HeadquartersCard";
import { OfficeLocationsCard, LocationsNavbar } from "src/components/pageComponents";
import countryCodes from "src/components/locations/countrycodes.json";
import mapStyles from "src/components/helpers/googleMapStyles";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import { SEO,Breadcrumbs,Accordion,MapMarker } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
const getImageObject = require("src/../helpers/getImageObject")

const HeroIllustration = "/accruent_locations_hero_office-illustration.svg";

const extractAddress = (obj) => {
  const a = obj.fieldPartnersAddress;
  return `${a.addressLine1} ${a.locality} ${a.postalCode}, ${a.countryCode}`;
};

function Locations({ data }) {
  //Organizing the data from locations query into an object for easier access in component

  const officeLocations = {};
  const countryList = [];
  let showMap = true;
  const [corporateHQ, setCorporateHQ] = useState(null);
  const [europeanHQ, setEuropeanHQ] = useState(null);

  data?.allLocations?.nodes?.forEach((item) => {
    if (
      item.title !== "Corporate Headquarters" &&
      item.title !== "European Headquarters"
    ) {
      const countryName = countryCodes[item.fieldPartnersAddress?.countryCode];
      if (!countryList.some((item) => item === countryName)) {
        countryList.push(countryName);
        countryList.sort();
      }

      officeLocations[countryName]
        ? officeLocations[countryName].push(item)
        : (officeLocations[countryName] = [item]);
    }
  });

  let fieldHeadquarters = data.locationsPage?.relationships?.fieldContentSections?.filter(( entity ) => {
    if (entity?.relationships?.fieldProxy?.type?.id === "headquarter_section") {
      return true
    }
  })?.[0]?.relationships?.fieldProxy?.relationships?.fieldHeadquarter
  if(!!fieldHeadquarters?.length){
    showMap = data.locationsPage?.relationships?.fieldContentSections?.[0]?.relationships?.fieldProxy?.fieldShowMap
  }
  // /* Note: temporarily commented out locations during COVID global lockdown*/

  const locationcards = Object.keys(officeLocations)
    .sort()
    .map((country, i) => (
      <Fragment key={country + i}>
        <h3
          className="title-4 has-text-weight-bold mb-2 mt-5 tw-font-black tw-mb-4 tw-mt-[3rem]"
          id={country.replace(" ", "").toLowerCase()}
        >
          {country}
        </h3>
        <div className="tw-columns">
          {officeLocations[country].map((location) => (
            <OfficeLocationsCard location={location} key={location.uuid} />
          ))}
        </div>
      </Fragment>
    ));

  const accordionData = Object.keys(officeLocations)
    .sort()
    .map((country) => {
      const content = (
        <div className="tw-columns tw-p-[1.5rem]">
          {officeLocations[country].map((location) => (
            <OfficeLocationsCard location={location} key={location.uuid} />
          ))}
        </div>
      );
      // const content = "test";
      return { title: country, content, id: country };
    });

  // // [{ title: String, content: String, id: String/Number }]

  const initGeocoder = ({ maps, map }) => {
    const Geocoder = new maps.Geocoder();
    const comporateAddress = fieldHeadquarters?.[0] ? extractAddress(
      fieldHeadquarters[0]
    ): null;
    const europeanAddress = fieldHeadquarters?.[1] ? extractAddress(
      fieldHeadquarters[1]
    ): null;
    Geocoder.geocode({ address: comporateAddress }, (results, status) => {
      if (status === maps.GeocoderStatus.OK) {
        setCorporateHQ(results[0]);
      }
    });
    Geocoder.geocode({ address: europeanAddress }, (results, status) => {
      if (status === maps.GeocoderStatus.OK) {
        setEuropeanHQ(results[0]);
      }
    });
  };
  // /* Note: temporarily commented out locations during COVID global lockdown*/

  const breadcrumbs = [
    { title: "Home", link: "/" },
    { title: "Company", link: "/about-us" },
    { title: "Locations" },
  ];
  const heroImage =  getImageObject(data?.locationsPage?.relationships?.fieldHeroMedia, {}, "fieldHeroMedia");
  const OGMarkup = getOGMarkup("Locations", 'about us', heroImage);
  const metatags = [
    { name: 'title', content: `Locations | Accruent` },
    { name: 'description', content: `With offices on four continents, Accruent offers comprehensive facilities and asset management software for businesses around the globe.` }
  ]
  const pageDataPath = getPageDataJsonPath("/about-us/locations")
  return (
    <Fragment>
      <SEO
        title="Locations" pageUrl="/about-us/locations"
        OGMarkup={OGMarkup}
        meta={metatags}
        alternatives={[
          {
            href: "/about-us/locations",
            hreflang: "en"
          },
          {
            href: "/about-us/locations",
            hreflang: "x-default"
          }
        ]}
        preloads= {[pageDataPath]}
        preloadedImages={[{src: HeroIllustration}]}
      />
      <div className="section locations-page-wrapper !tw-pb-0">
        <div className="container">
          <div className="columns">
            <div className="column lg:tw-w-[50%] lg:tw-flex-none">
              <h1 className="title-2 tw-mb-[1.5rem] tw-break-normal">
                {data.locationsPage.title}
              </h1>
              <p className="tw-text-[1.25rem] tw-mb-[3rem]">
                {data.locationsPage.fieldSubtitle}
              </p>
              {!!fieldHeadquarters?.length && <h3 className="title-4 tw-mb-[2rem] tw-break-normal">Headquarters</h3>}
              {!!fieldHeadquarters?.length && <div className="columns">
                {fieldHeadquarters.map(
                  (headquarter) => (
                    <HeadquartersCard
                      headquarter={headquarter}
                      key={headquarter.uuid}
                    />
                  )
                )}
              </div>}
            </div>
            <div className="column tw-hidden lg:tw-block lg:tw-w-[50%] lg:tw-flex-none">
              <figure className="image tw-block tw-relative tw-pt-[100%]">
                <img
                  src={HeroIllustration}
                  alt="Accruent - Locations - Hero - Office Illustration"
                  decoding = "async"
                  className="tw-h-full tw-w-full tw-block tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-right-0"
                />
              </figure>
            </div>
          </div>
        </div>
        {showMap && !!fieldHeadquarters?.length && <div className="map-section tw-w-[100vw] tw-relative tw-left-[50%] tw-right-[50%] tw-h-[356px] tw-bg-cover tw-mx-[-50vw] tw-mt-[3rem]">
          <GoogleMapReact
            defaultCenter={{ lat: 39.45, lng: -45.39 }}
            defaultZoom={2}
            bootstrapURLKeys={{
              key: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={initGeocoder}
            options={{ styles: mapStyles }}
          >
            {corporateHQ && (
              <MapMarker
                lat={corporateHQ.geometry.location.lat()}
                lng={corporateHQ.geometry.location.lng()}
                // lat={59.955413}
                // lng={30.337844}
                text={"Corporate Headquarters"}
              />
            )}
            {europeanHQ && (
              <MapMarker
                lat={europeanHQ.geometry.location.lat()}
                lng={europeanHQ.geometry.location.lng()}
                // lat={59.955413}
                // lng={30.337844}
                text={"European Headquarters"}
              />
            )}
          </GoogleMapReact>
        </div>}

        <div className="container after-the-map tw-mt-[29px]">
          <div className="columns tw-items-center">
            <div className="column lg:tw-flex-none lg:tw-w-[66.66%]">
              {/* Note: temporarily commented out locations during COVID global lockdown*/}
              <h3 className="title-3 tw-uppercase">
                our offices worldwide.
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* Note: temporarily commented out locations during COVID global lockdown*/}
      <LocationsNavbar countryList={countryList} />
      <div className="section locations-page-wrapper">
        <div className="container">
          <Breadcrumbs crumbs={breadcrumbs} />
          <div className=" tw-hidden lg:tw-block">{locationcards}</div>
        </div>
      </div>
      <div className="lg:tw-hidden tw-block tw-mb-[3rem]">
        {accordionData && (
          <Accordion data={accordionData} isNarrow initiallyClosed />
        )}
      </div>
    </Fragment>
  );
}

export const query = graphql`
  {
      allLocations:   allNodeLocations(limit: 1000) {
        nodes {
          ...locations 
        }
      } 
      locationsPage: nodeAboutUs(drupal_internal__nid: {eq: 4583}) {
        nid: drupal_internal__nid
        uuid: drupal_id
        entityId: id
        title
        entityLabel: title
        fieldSubtitle: field_subtitle
        relationships {
          fieldHeroMedia: field_hero_media {
            fieldImage: field_image {
              alt
    
            }
            relationships {
              fieldImage: field_image {
                gatsbyImage(width: 10, layout: FULL_WIDTH, placeholder:BLURRED)
              }
            }
          }
          fieldContentSections: field_content_sections {
            relationships {
              fieldProxy: field_proxy {
                ... on node__headquarter_section {
                  type: node_type {
                    id: drupal_internal__target_id
                  }
                  relationships {
                    fieldHeadquarter: field_headquarter {
                      ...locations
                    }
                  }
                  fieldShowMap: field_show_map
                }
                  }
                }
            }
        }
      }
  }
`;

export default Locations;
